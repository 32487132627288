import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ReactHtmlParser from "react-html-parser";
import Layout from "../components/layout"
import { graphql } from "gatsby"
import clsx from "clsx"
import Seo from "../components/SEO/seo";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    maxWidth: 700,
    "& .image-border": {
      border: "3px solid rgba(var(--primary),0.3)",
      background: palette.primary.contrastText,
    },

    "&:after": {
      content: '" "',
      position: "absolute",
      display: "block",
      top: 0,
      bottom: 0,
      left: "calc(50% - 120px)",
      right: "calc(50% - 175px)",
      background: "rgba(var(--primary),0.15)",
      clipPath: "polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%)",
      zIndex: -1,
      [theme.breakpoints.down("xs")]: {
        right: 0,
        left: 0,
        clipPath: "none",
      },
    },
  },
  intro: {
    padding: "200px 0 0 !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "60px 0 0 !important",
    },
  },
  subContainer:{
    marginBottom:'3%'
  }
}))
const PromesaLanding = ({ data }) => {
  let {
    landingPages,
  } = data
  const theme = useTheme()
  const landingPagess = landingPages.edges.map(e => e.node)
  const recorrerLandingConcienciaEc = landingPagess.find((filtrar) => {
    return filtrar.TipoLanding === 10 && filtrar.EntityID ===21;
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()

  return (
    <React.Fragment>
    <Layout>
    <div className={clsx("landing ", classes.intro)} >
      <div className={classes.subContainer}>
      <div className="container text-center">

          {ReactHtmlParser(isMobile ? recorrerLandingConcienciaEc.CuerpoPajinaMovil : recorrerLandingConcienciaEc.CuerpoPagina)}
      </div>
      </div>    
    </div>
    </Layout>
    </React.Fragment>
  );
};

export default PromesaLanding;
export const pageQuery = graphql`
query {
landingPages: allLandingPajeJsonJson{
  edges {
    node {          
      EntityID
      IdDivision          
      IdLandingPage
      IdCompania
      TipoLanding
      Titulo
      Subtitulo
      CuerpoPagina
      CuerpoPajinaMovil        
      }
    }
  }
}
`
